// Navbar.js

import React from 'react';
import './Navbar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  
  faSearchengin,
} from "@fortawesome/free-brands-svg-icons";


const Navbar = () => {
  return (
    <div className="navbar">
      {/* <img src="./image.jpg" alt="Logo" className="logo" /> */}
      <div>  <p className='ecb-Box'>Welcome ECB Services </p></div>
      <div> <p className="ecb-Boxone">Office Hours: 10:00am - 7:00pm</p></div>
      <div className="social-icons">
  <a href="#" className="social-icon">
    <FontAwesomeIcon icon={faFacebook}  />
  </a>
  <a href="#" className="social-icon">
    <FontAwesomeIcon icon={faTwitter}  />
  </a>
  <a href="#" className="social-icon ">
    <FontAwesomeIcon icon={faInstagram}  />
  </a>
  <a href="#" className="social-icon ">
    <FontAwesomeIcon icon={faYoutube}  />
  </a>

  
</div>

<div className='icon special'>
<a href="#" className="social-icon s" >
    <FontAwesomeIcon icon={faSearchengin} />
    
  </a>

</div>


</div>
  );
};

export default Navbar;
