// TestimonialsPage.js
import React from 'react';
// import './Testimonial.css';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';


const  TestimonialsPage = () => {
    return (
      <div className="client-container">
        <div className="text-container">
          <h1>Client Testimonials</h1>
          <h3> What our Clients has to say about us?</h3>

          

          
          
            
        </div>
       
      </div>

    //  const bull = (
    //     <Box
    //       component="span"
    //       sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    //     >
    //       •
    //     </Box>
    //   );
      
  //     export default function BasicCard() {
  //       return (
  //         <Card sx={{ minWidth: 275 }}>
  //           <CardContent>
  //             <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
  //               Word of the Day
  //             </Typography>
  //             <Typography variant="h5" component="div">
  //               be{bull}nev{bull}o{bull}lent
  //             </Typography>
  //             <Typography sx={{ mb: 1.5 }} color="text.secondary">
  //               adjective
  //             </Typography>
  //             <Typography variant="body2">
  //               well meaning and kindly.
  //               <br />
  //               {'"a benevolent smile"'}
  //             </Typography>
  //           </CardContent>
  //           <CardActions>
  //             <Button size="small">Learn More</Button>
  //           </CardActions>
  //         </Card>
  //       );
  //     }


        
    );
  };

  export default  TestimonialsPage;