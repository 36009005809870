import React, { useState } from 'react';
import './Navb.css';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import AboutPage from './About';
// import { useState } from 'react';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
   
};

const servicesList = [
    'CFE (Consent for Establishment)',
    'CFO (Consent for Operation)',
    'Detailed Project Report (DPR)',
    'E - Waste Recycling (Solar, Drone …….)',
    'Safety Audits',
    'Risk Assessments',
    'Plastic Waste Recycling',
    'Extended Producer Responsibility (EPR)',
    'EC Compliance',
    'Lithium-Ion & Lead Acid Batteries Recycling',
]

  const items = [
    // <img src="./image.jpg" alt="Image 1" className="item" data-value="1" style={{ width: '100%', height: '100vh' }} />,
    <img src="https://media.visualstories.com/uploads/images/1/157/5436657-1280_450909067-save-nature_l.jpg" alt="Image 2" className="item" data-value="2" />,
    <img src="https://wallpapercave.com/wp/wp4538244.jpg" alt="Image 3" className="item" data-value="3"   />,
//     <img src="./home2.png" alt="Image 4" className="item" data-value="4" />,
    <img src="./home1.png" alt="Image 5" className="item" data-value="5"  />,
];

const headers = [
  {
      number: '01.',
      text: 'SCHEDULING',
      description:
          'BEFORE ANY WORK STARTS, WE RECORD THE GOALS, THE ACTIVITIES, THE EXPENSES, AND THE TIMELINE.',
  },
  {
      number: '02.',
      text: 'WORK EXECUTION',
      description:
          'WE EXECUTE THE WORKS BY USING METHODICAL TECHNIQUES AS PER THE STANDARDS',
  },
  {
      number: '03.',
      text: 'ONSITE /LABORATORY TESTING',
      description:
          'WE PERFORM ONSITE ANALYSIS AS PER THE SCOPE OF WORK & THE SAMPLES WHICH WERE COLLECTED WILL BE  SENT TO LABORATORY AS SOON AS POSSIBLE TO PERFORM THE ANALYSIS OF REQUIRED PARAMETERS',
  },
  // Add similar objects for the remaining steps
];

const header = [
  {
    number: '04.',
      text: 'ASSESSMENT',
      description:
          'WE PROVIDE RECOMMENDATIONS, IF NECESSARY, AFTER ASSESSING THE RESULTS',
  },
  {
      number:'05.',
      text: 'REPORTING',
      description:
          'WE REPORT THE ANALYSED RESULTS AS PER THE STANDARD FORMAT',
  },
  {
      number:'06.',
      text: 'FEEDBACK',
      description:
          'TO MAKE IMPROVEMENTS TO OUR SERVICES, WE SOLICIT INPUT FROM OUR CLIENTS.',
  },
  // Add similar objects for the remaining steps
];

const Navb = () => {
    const [isServicesOpen, setServicesOpen] = useState(false);

  const toggleServices = () => {
    setServicesOpen(!isServicesOpen);
  };

    
  return (
    <div className="nav-container">
      
      
      <nav className="navb">
        <ul className="nav-list">
         
          <li className="nav-item"><a href="#">Home</a></li>
          <li className="nav-item"><a href="#">About Us</a></li>
          <li className="nav-item">
            <a href="sever">Services</a>
            <div className="services-box">
              <ul className="services-list">
                {servicesList.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            </div>
          </li>
          <li className="nav-item"><a href="#">Unique Services</a></li>
          <li className="nav-item"><a href="#">My Blog</a></li>
          <li className="nav-item"><a href="#">Contact Us</a></li>
          
          <li className="nav-item"><a href="#">Testimonials</a></li>

        </ul>
      </nav>

    <AliceCarousel
         items={items}
      
         disableButtonsControls
         autoPlay
         autoPlayInterval={1000}
         infinite
    />
    <div className="headers">
    {headers.map((header, index) => (
        <div key={index} className="header">
            
            <div>

              <p className='header-number'>{header.number}</p>
                <p className="header-text">{header.text}</p>
                <p className="header-description">{header.description}</p>
            </div>
        </div>
    ))}
</div>
<div className="headers">
    {header.map((header, index) => (
        <div key={index} className="header">
            
            <div>
            <p className='header-number'>{header.number}</p>
                <p className="header-text">{header.text}</p>
                <p className="header-description">{header.description}</p>
            </div>
        </div>
    ))}
</div>
<AboutPage/>
    </div>
  );
};

export default Navb;
