// HappyPage.js
import React from 'react';

import './Happy.css';
const HappyPage = () => {
    return (
      <div className="Quality-container">
       {/* <div className="text-container"> */}

       <div>  <p className='Happy Clients '>Welcome ECB Services </p></div>
      <div> <p className="">Years Completed: 10:00am - 7:00pm</p></div>
      <div>  <p className='Fortune 1000 '> </p></div>
          
         
          
        
        {/* </div> */}
        
      </div>
    );
  };
 
  export default HappyPage;