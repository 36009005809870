// import logo from './logo.svg';
// import './App.css';start
import Nav from './comp/Nav';
import Navb from './comp/Navb';
import Navbar from './comp/Navbar';

import QualitySolutionsPage from './comp/Quality';
import  TestimonialsPage from './comp/Testimonial';
import SpecialPage from './comp/special';
import FrequentlyPage from './comp/Frequently';
import HappyPage from './comp/Happy';

import Schedulingpage from './comp/Scheduling';
import ChoosePage from './comp/Choose';
// import UniquePage from '/comp/unique';


function App() {
  return (
    <div className="App">
     <Navbar/>
     <Nav/>
     <Navb/>
     <FrequentlyPage/>
     <HappyPage/>
     <ChoosePage/>
     {/* <UniquePage/> */}
    
    
    <Schedulingpage/>
    
     
    

     <QualitySolutionsPage/>
     <TestimonialsPage/>
     <SpecialPage/>
     
     
     
    </div>
  );
}

export default App;
