//Specialpage.js
import React from 'react';
import './special.css';


import Button from '@mui/material/Button';



const SpecialPage = () => {
    return (
      <div className="Special-container">
       {/* <div className="text-container"> */}
       <h1 className='container-header'>Get Special Discount!</h1>

        <p className='container'>
        We offer Special discounts to Loyalty Members, Seasonal, Promotional and Bulk Volume for SME and SMB Companies..
          </p> 
         
          
        
          
        <Button variant="outlined" className='container-button'>CALL OUR Experts NOW</Button>
        
        {/* </div> */}
        
      </div>
    );
  };
 
  export default SpecialPage;