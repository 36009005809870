// //Schedulingpage.js
// import React from 'react';
// import './scheduling.css';


// // import Button from '@mui/material/Button';



// const Schedulingpage  = () => {
//     return (
//      <div>
//       <div className='Big'>HOME:</div>
//      </div>
//     );
//   };

//   export default Schedulingpage;

// SchedulingPage.js

import React from 'react';
import './About.css';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';


// export default function MediaControlCard() {
//   const theme = useTheme();

const SchedulingPage = () => {
  return (

    <card>

    
    



    <div className="about-container">
      <div className="text-container">
        <h1 className='Home-box'>HOME:</h1>
        {/* <h2 className='pollucon-class'>Perfect Pollucon Services Offers Environmental Monitoring Services</h2> */}
        <p className='House class'>
         •SCHEDULING  </p>
         <p> •WORK EXECUTION</p>
        

         <h3>•BEFORE ANY WORK STARTS, WE RECORD THE GOALS, THE 
ACTIVITIES, THE EXPENSES, AND THE TIMELINE</h3>
<p className='House class'>
         •WORK EXECUTION </p>




<p>ECB Services brings decades of experience to every 
project and offers unparalleled quality and attention to 
detail</p>
       
      
      
       
      </div>
      <div className='Picture-class'>
      <img src="./Picture2.jpg" alt="Environment Image" className="about-image" />
      {/* <a href="#read-more-link">READ MORE</a> */}

     
    </div>
    </div>
     </card>
  );
};

export default SchedulingPage;
