
// ChoosePage.js

import React from 'react';
import './Choose.css';



import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

const  ChoosePage = () => {
  return (

 

    <div>
       <div className='choose'> WHY TO CHOOSE ECB SERVICES?</div>
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
     
    >
    <Grid container justifyContent="center"  rowSpacing={4} columnSpacing={4}>
      <Grid  item xs={3}  >
        <Item  className='intemA'>Successfully obtained CFE, CFO, Authorizations, EPR FOR PROJECTS. 
                      </Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemB'>One stop solution for all recycle plant’s needs (DPR, CFE, CFE Compliance, CFO Compliance, Authorizations, EPR, etc
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemc'>Highly qualified and experienced team of environmental professionals.
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemD'> clTransparent and Streamlined dealings
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemE'>Proper guidance to the clients at every stage of the CFE, CFO, Authorizations and EPR process  
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemF'>Systematic approach & Follow-up with the respective government authorities.
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemG'> Timely services so that the project stays on schedule.
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemh'> High quality report preparation in accordance with the latest statutory norms.
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemI'>Staying up to date and being aware of the latest statutory norms.
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemJ'>Appropriate solutions & advice to our globalised clients.
</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='intemk'>We consistently provide our services at affordable prices and value for money.
</Item>
      </Grid>
      
    </Grid>
    </Stack>
  </div>




    
    
       
      
      
      


  );
};

export default  ChoosePage;
