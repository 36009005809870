// AboutUsPage.js

import React from 'react';
import './About.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PersonIcon from '@mui/icons-material/Person';
import HubIcon from '@mui/icons-material/Hub';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const AboutUsPage = () => {
  return (
    <div className="about-container">
      <div className="text-container">
        {/* <h1 className='About-class'>ABOUT ECB SERVICES</h1> */}
        <div>ABOUT ECB SERVICES</div>
        {/* <h2 className='pollucon-class'>Perfect Pollucon Services Offers Environmental Monitoring Services</h2> */}
        <Box sx={{ width: '100%' }}>
      <Grid container
       rowSpacing={5}
        // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          
        <Grid item xs={12}>
          
          <Item className='item1'>
              {/* <div class="icon"> */}
  {/* <input class="input"></input> */}
  {/* <img src="./icon1.ico
  " alt="Environment Image" className="about-image" />
  </div> */}
  <HandshakeIcon  className='hand'/>

  ECB Services was established in the year 2023 with a vision to promote Environmentally Sustainable Economic Development through our environmental Consultancy & Laboratory services.
          {/* <div class="wrapper"> */}
 
{/* </div>   */}
</Item>
        </Grid>
        <Grid item xs={12}>
          <Item className='itme2'> 
          <PersonIcon  className='Person'/>
            It was established with a group of highly qualified and experienced professionals to provide high quality Consultancy and Contracting Services.
</Item>

        </Grid>
        <Grid item xs={12}>
          <Item  className='item3'>
          <HubIcon/>
            
            ECB Services brings decades of experience to every project and offers unparalleled quality and attention to detail.
</Item>
        </Grid>
        
      </Grid>
    </Box>

        
        {/* <p className='more-class'>(more…)</p> */}
      
        {/* <Button  className='button-class' variant="contained" >Read More</Button> */}
      </div>
      {/* <div className='image-class'>
      <img src="./Picture1.png" alt="Environment Image" className="about-image" />
      

     
    </div> */}

{/* <div class="wrapper">
  <div class="icon"></div>
  <input class="input"></input>
  <img src="./icon1.ico
  " alt="Environment Image" className="about-image" />
</div>   */}
    </div>
  );
};

export default AboutUsPage;
