import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { blue } from '@mui/material/colors';
import { Margin } from '@mui/icons-material';




export default function SvgMaterialIcons() {
  return (
//      <div className='primary-class'>
    <Grid  className='gridbox' container sx={{ backgroundColor:'blue',}}>
      <Grid 
    //  item xs={8}
      >
        
        {/* <CheckIcon />
         */}
      </Grid>
      <Grid className=''
       item xs={4}
       >
      
        <h1 className='class'>ENVIRONMENTAL CONSULTANCY SERVICES</h1>

        <Typography>CFE & CFO RENEWALS</Typography>
        <Typography>CFO (CONSENT FOR OPERATION) APPROVALCFO</Typography>
        <Typography>CFE & CFO RENEWALS
</Typography>
        <Typography>Biomedical Waste AUTHORIZATION
</Typography>
        <Typography>Detailed Project Report (DPR)


</Typography>
        <Typography>Extended Producer Responsibility (EPR)

</Typography>
        <Typography>EC Compliance
</Typography>
        <Typography>) ENVIRONMENTAL STATEMENT (FORM V
</Typography>
        <Typography>ENVIRONMENTAL AUDIT
</Typography>
        <Typography>CFO & CFE COMPLIANCE REPORT PREPARATION
</Typography>
        <Typography>E - Waste & Plastic Waste AUTHORIZATION  
</Typography>
        <Typography>Construction and Demolition Waste Recycling 
</Typography>
        <Typography>Tyre Waste & Pyrolysis Recycling 
</Typography>
        <Typography>Hazardous waste Recycling 
</Typography>
<Typography>Compostable Plastic Packaging Recycle   

</Typography>
<Typography>Treatment, Storage, Disposal Facility (TSDF'S 
</Typography>
<Typography>Used Oil/Spent Oil Recycling  

</Typography>
<Typography>Rubber & Leather Recycling 
</Typography>
<Typography>STP/ETP Recycling 

</Typography>


      </Grid>
      
      
      
      
    </Grid>
//     </div>
  );
}
