// FrequentlyPage.js
import React from 'react';
import './Frequently.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


// // const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >
//     •
//   </Box>
// );



const FrequentlyPage = () => {
    return (
      <section className="card-container">
      <Card 
      //  className='card' 
       >
         <CardContent>

         
      {/* <section className="container"> */}

          <h2 className='Frequently'>ECB SERVICES:</h2>
          <h5 className='Monitoring'>Environmental Monitoring Services:
:</h5>
          <div className='Description'>Ambient Air Quality monitoring 
<div>Indoor Air quality monitoring </div>
<div> Noise Level MONITORING</div>
<div>Stack emission MONITORING</div>
<div>Soil Analysis</div>
<div>Water quality analysis</div>
<div>Illumination survey</div>
<div>VENTILLATION STUDIES</div>
<div>COMPRESSED AIR ANALYSIS</div>
<div>FUGITIVE EMISSION MONITORING</div>
<div>PERSONAL NOISE MONITORING</div>
<div>PERSONAL DUST MONITORING</div>
</div>




          
          {/* <h3 className='enabled'> What is NABL?</h3>
          
          <h3 className='enabled-class'> The National Accreditation Board for Testing and Calibration Laboratories (NABL) undertakes the assessment and accreditation of Testing and Calibration Laboratories, in accordance with the international standard ISO / IEC 17025 and ISO 15189. </h3>
     
          <h3 className='tab1'>What is covered in Environmental Monitoring?       +</h3>
          <h3 className='tab2'>What is the Process of Environmental Monitoring?</h3> */}
         
          

        
        
        {/* <img src="https://img.freepik.com/free-vector/gradient-our-mission-infographics_23-2149081669.jpg?w=900&t=st=1699586794~exp=1699587394~hmac=a72d3c66ec27f792c54a5c9f833ea985dbaa5b760cf18e6a25f25455fece24ef" alt="Environment Image" className="about-image" />
        <a href="#read-more-link">READ MORE</a> */}
      {/* </section> */}
      </CardContent>
      </Card>
      </section>
    );
  };
  
  export default FrequentlyPage;




