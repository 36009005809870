import React from 'react';
import './Nav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope,faMapMarker } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const PerfectPolluconServices = () => {
  return (
    // <div style={{ display: 'flex', alignItems: 'center',  background: "white", color: 'black', padding: '10px'  }}>
      <div className='ecb-class'>
      
      <div style={{ display: 'flex', alignItems: 'center' }}>
  <img src="./image.jpg" alt="Your Logo" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
  <h1 style={{ fontSize: '17px', color: 'green' }}>
    ECB Services
    <p style={{ marginTop: '2px', color: 'blue', fontSize: '15px' }}>Environmental Consultant & Laboratory</p>
  </h1>
</div>
  
    
      <div className="section-box">
      <FontAwesomeIcon icon={faPhone} style={{ fontSize: '50px', color: 'green', marginTop: '15px' }} />
      </div>
  <div className="section-content">
    <h1 style={{ fontSize: '20px', color: "black", marginRight:"15px",marginTop: '15px' }}>
     
      Call US:
    </h1>
    <p style={{ marginTop: '16px', color: "#7E7E7E", fontSize: "17px",marginRight:"20px" }}>(+91)8688427728</p>
  </div>


<div className="section-box">
<FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '50px', color: 'green', marginTop: '15px' }} />
</div>
  <div className="section-content">
  <h1 style={{ fontSize: '17px', color: "black",marginTop: '15px' }}>
  <a href="mailto:info@ecbservices.co.in" style={{ textDecoration: 'none' }}>
 
  <span style={{ color: 'black',fontSize: "20px",marginBottom:"0px",marginTop: '15px' }}>Email:</span>
</a>


</h1>
<p style={{marginTop:"0px", color: "#7E7E7E", fontSize: "17px",marginRight:"20px" }}>info@ecbservices.co.in</p>

  </div>


<div className="section-box">
   <FontAwesomeIcon icon={faMapMarker} style={{ fontSize: '50px', color: 'green',marginTop: '15px '}} />
   </div>
  <div className="section-content">
    <h1 style={{ fontSize: '20px', color: "black",marginTop:"15px" }}>
     
  Location:
    </h1>
    <p style={{  color: "#7E7E7E", fontSize: "17px",marginTop:"10px" }}>Ground Floor,6-28-31,Parvathpur,Medchal Malkajgiri,Hyderabad,Telangana,500098</p>
  </div>




     
    </div>
  );
};

export default PerfectPolluconServices;
